export enum API {
  IMAGE = "https://dev-api.jobfi.vn/v1/api",
  LOCAL = "http://localhost:3000/v1/api"
}

export const CONSTANT = {
  IMAGE: process.env.REACT_APP_IMAGE_URL ?? "https://dev-api.jobfi.vn/v1/api",
  JOBSEEKER_URL: process.env.JOBSEEKER_URL || "https://dev.jobfi.vn",
  RECAPTCHA_KEY: "6LdDCMEnAAAAAFxozSaoqXhCNu4TtrCy2ryk7EqC"
};

export enum Level {
  INTERN = "intern",
  FRESHER = "fresher",
  JUNIOR = "junior",
  MIDDLE = "middle",
  SENIOR = "senior",
  MANAGER = "manager",
  DIRECTOR = "director"
}
export enum Role {
  USER = "U_USER",
  RECRUITER = "U_RECRUITER",
  SALE = "U_SALE",
  SALE_ADMIN = "U_SALE_ADMIN",
  AMIN_OF_SALE = "U_ADMIN_OF_SALE",
  ADMIN = "U_ADMIN",
  SUPER_ADMIN = "U_SUPER_ADMIN",
  SALE_ADMIN_SYSTEM = "U_ADMIN_OF_SALE",
  U_INPUT = "U_INPUT",
  U_CONTENT = "U_CONTENT",
  U_STAFF_RECEIPT = "U_STAFF_RECEIPT",
  U_STAFF_TECH = "U_STAFF_TECH"
}
export enum Time {
  LOADING = 250
}
export enum InputType {
  EMAIL = "email",
  PASSWORD = "password",
  TEXT = "text"
}
export enum DateFormat {
  VI_TIME = "dd-MM-yyyy HH:mm:ss",
  VI_TIME_MOMENT = "DD-MM-YYYY HH:mm:ss",
  VI_HOURS = "HH:mm:ss",
  VI = "dd-MM-yyyy",
  VI_MOMENT = "DD-MM-YYYY",
  TIME_MONTH_YEAR = "yyyy-MM",
  EN = "yyyy-MM-dd",
  EN_MOMENT = "YYYY-MM-DD",
  EN_TIME_MOMENT = "yyyy-MM-dd HH:mm:ss"
}
export enum UserStatus {
  INACTIVE = "U_INACTIVE",
  ACTIVE = "U_ACTIVE",
  BLOCK = "U_BLOCK",
  REMOVE = "U_REMOVE",
  REQ_CHANGE_PASSWORD = "U_REQ_CHANGE_PASSWORD",
  NEED_VERIFY_OTP = "U_NEED_VERIFY_OTP"
}
export enum JobProfileActionTypeSubmit {
  CV = "JP_CV",
  UPLOAD_FILE = "JP_UPLOAD_FILE",
  UPLOAD_FILE_VERIFIED = "JP_UPLOAD_FILE_VERIFIED"
}
export enum JobProfileActionStatus {
  JP_SUBMITTED = "JP_SUBMITTED",
  JP_WAITING_INTERVIEW = "JP_WAITING_INTERVIEW",
  JP_REJECT = "JP_REJECT"
}
export enum ECommonEmailTemplateModel {
  APPLY_JOB = "APPLY_JOB",
  THANKS_FOR_APPLY_JOB_SUCCESS = "THANKS_FOR_APPLY_JOB_SUCCESS",
  THANKS_FOR_EMPLOYER_REJECT = "THANKS_FOR_EMPLOYER_REJECT",
  MEETING_REQUEST = "MEETING_REQUEST",
  RESIGNATION_LETTER = "RESIGNATION_LETTER",
  LEAVE_REQUEST_LETTER = "LEAVE_REQUEST_LETTER"
}
export enum ESurveyCandidateStatus {
  NOT_JOIN_SURVEY_CAMPAIGN = 1,
  POINTS_EARNED = 2,
  HAVENOT_CREATE_ACCOUNT_YET = 3
}
export enum EFAQCategoryStatus {
  active = 1,
  draft = 2
}

export enum EJobCommentStatus {
  APPROVED = 1,
  REJECTED = 2,
  PENDING = 3
}
export enum ENotificationJobComment {
  CREATED_COMMENT = "CREATED_COMMENT",
  CREATED_REPLY_COMMENT = "CREATED_REPLY_COMMENT"
}
