import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction
} from "@reduxjs/toolkit";

import AuthenticateReducer from "./Slices/Authenticate/index";
import DialogReducer from "./Slices/Dialog";
import HomeReducer from "./Slices/Home/index";
import RecruitReducer from "./Slices/Recruit";
import CommonsReducer from "./Slices/commons";
import PaginationReducer from "./Slices/pagination";

export const rootReducer = combineReducers({
  commons: CommonsReducer,
  pagination: PaginationReducer,
  authenticate: AuthenticateReducer,
  home: HomeReducer,
  recruit: RecruitReducer,
  dialog: DialogReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
