import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface variableState {
  id: string;
  triggerCol: number;
  index: number;
  companyDetail: any[];
  listCity: any[];
  listDistrict: any[];
  listWard: any[];
}

const initialState: variableState = {
  id: "",
  triggerCol: -1,
  index: 0,
  companyDetail: [],
  listCity: [],
  listDistrict: [],
  listWard: []
};

const recruitSlice = createSlice({
  name: "recruit",
  initialState,
  reducers: {
    setId(state, action) {
      state.id = action.payload;
    },
    setTriggerCol(state, action) {
      state.triggerCol = action.payload;
    },
    setIndex(state, action) {
      state.index = action.payload;
    },
    setCompanyDetail(state, action) {
      state.companyDetail = action.payload;
    },
    setListCity(state, action) {
      state.listCity = action.payload;
    },
    setListDistrict(state, action) {
      state.listDistrict = action.payload;
    },
    setListWard(state, action) {
      state.listWard = action.payload;
    }
  }
});

//Actions
export const recruitActions = recruitSlice.actions;

//Selectors
export const selectorSetId = (state: RootState) => state.recruit.id;
export const selectorSetTriggerCol = (state: RootState) =>
  state.recruit.triggerCol;
export const selectorSetIndex = (state: RootState) => state.recruit.index;
export const selectorSetCompanyDetail = (state: RootState) =>
  state.recruit.companyDetail;
export const selectorSetListCity = (state: RootState) => state.recruit.listCity;
export const selectorSetListDistrict = (state: RootState) =>
  state.recruit.listDistrict;
export const selectorSetListWard = (state: RootState) => state.recruit.listWard;

//reducer
export default recruitSlice.reducer;
