import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";

import type { Router, Session } from "@toolpad/core";
import { AppProvider, DashboardLayout } from "@toolpad/core";
import Cookies from "js-cookie";

import Background from "../../asset/img/Login/bg.jpg";
import ImgUser from "../../asset/img/img_avatar.png";
import logo from "../../asset/logo.png";
import { Role } from "../../constant/common";
import { RoutesRedirect } from "../../constant/routes";
import {
  NAVIGATION,
  NAVIGATION_CONTENT,
  NAVIGATION_INPUT
} from "../../constant/sidebar";
import { NotificationSystem } from "./Notification";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",

  "& header": {},

  "& .css-1iippzj": {
    overflow: "auto"
  },

  ":after": {
    content: `""`,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.7,
    zIndex: -1,
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100vh"
  },

  "& .MuiDrawer-paper": {
    minHeight: "100vh"
  }
}));

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme"
  },
  colorSchemes: { dark: true, light: true },
  defaultColorScheme: "dark"
});

function DemoPageContent() {
  return (
    <Box>
      <Outlet />
    </Box>
  );
}

interface DemoProps {
  windows?: () => Window;
}

function MainLayoutPrivate(props: DemoProps) {
  const getToken = Cookies.get("access_token");
  const getUser = localStorage.getItem("userInfor");
  const role = Cookies.get("role") || "";

  const { windows } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [userInfo, setUserInfo] = useState<any>();
  const [session, setSession] = useState<Session | null>({
    user: {
      name: `${userInfo?.last_name} ${userInfo?.first_name}` || "N/A",
      email: userInfo?.email || "",
      image: userInfo?.avatar || ImgUser
    }
  });
  const [pathname, setPathname] = useState("/");

  const demoWindow = windows !== undefined ? windows() : undefined;

  const router = useMemo<Router>(() => {
    return {
      pathname: `${location.pathname}`,
      searchParams: new URLSearchParams(),
      navigate: (path) => {
        setPathname(String(path));
        navigate(path, { replace: true });
      }
    };
  }, [pathname]);

  const handleLogOut = () => {
    Cookies.remove("role");
    Cookies.remove("access_token");
    localStorage.removeItem("userInfor");
    navigate(RoutesRedirect.loginPage, { replace: true });
  };

  const authentication = useMemo(() => {
    return {
      signIn: () => {},
      signOut: () => {
        setSession(null);
        handleLogOut();
      }
    };
  }, []);

  useEffect(() => {
    if (getToken === undefined) {
      handleLogOut();
    }
  }, []);

  useEffect(() => {
    if (getUser) {
      setUserInfo(JSON.parse(getUser));
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      setSession({
        user: {
          name: `${userInfo?.last_name} ${userInfo?.first_name}` || "N/A",
          email: userInfo?.email || "N/A",
          image: userInfo?.avatar || ImgUser
        }
      });
    }
  }, [userInfo]);

  const SetRoleNavigate = (role: string) => {
    switch (role) {
      case Role.U_INPUT:
        return NAVIGATION_INPUT;
      case Role.U_CONTENT:
        return NAVIGATION_CONTENT;
      default:
        return NAVIGATION.filter((item: any) =>
          item.role?.includes(userInfo?.role)
        );
    }
  };

  const renderTilePage = (role: string) => {
    switch (role) {
      case Role.AMIN_OF_SALE:
        return "Admin Of Sale";
      case Role.U_INPUT:
        return "Input";
      case Role.U_CONTENT:
        return "Content";
      default:
        return "Admin";
    }
  };

  return (
    <>
      {getToken !== undefined && (
        <AppProvider
          navigation={SetRoleNavigate(userInfo?.role)}
          router={router}
          theme={demoTheme}
          window={demoWindow}
          session={session}
          authentication={authentication}
          key={location.pathname}
          branding={{
            title: renderTilePage(userInfo?.role),
            logo: <img src={logo} alt="logo" width={40} height={40} />
          }}
          children={
            <StyledBox>
              {role !== Role.U_INPUT && <NotificationSystem />}
              <DashboardLayout>
                <DemoPageContent />
              </DashboardLayout>
            </StyledBox>
          }
        />
      )}
    </>
  );
}
export default MainLayoutPrivate;
