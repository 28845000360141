import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface variableState {
	edit: boolean;
}

const initialState: variableState = {
	edit: false,
};

const dialogSlice = createSlice({
	name: 'dialogs',
	initialState,
	reducers: {
		setEdit(state, action) {
			state.edit = action.payload;
		},
	},
});

//Actions
export const dialogActions = dialogSlice.actions;

//Selectors
export const selectorSetEdit = (state: RootState) => state.dialog.edit;

//reducer
export default dialogSlice.reducer;
