import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6"
    },
    secondary: {
      main: "#19857b"
    },
    error: {
      main: red.A400
    },
    success: {
      main: "#4caf50",
      contrastText: "#fff"
    },
    warning: {
      main: "#FFD700",
      contrastText: "#FF8000"
    }
  }
});

export default theme;
