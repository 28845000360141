import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface authState {
	openDrawer: boolean;
}

const initialState: authState = {
	openDrawer: false,
};

const commonSlice = createSlice({
	name: 'commons',
	initialState,
	reducers: {
		setOpenDrawer(state, action) {
			state.openDrawer = action.payload;
		},
	},
});

//Actions
export const commonsActions = commonSlice.actions;

//Selectors
export const selectorSetOpenDrawer = (state: RootState) =>
	state.commons.openDrawer;

//reducer
export default commonSlice.reducer;
