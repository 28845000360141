import * as React from "react";

import { IconButton, IconButtonProps, SxProps, Theme } from "@mui/material";
import Popover, { PopoverProps } from "@mui/material/Popover";

interface IIconButtonDeleteProps {
  icon: React.ReactNode;
  content: React.ReactNode;
  sxPopover?: SxProps<Theme>;
  propsIcon?: IconButtonProps;
}

export default function IconButtonPopover({
  icon,
  content,
  sxPopover,
  propsIcon
}: IIconButtonDeleteProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleClick} {...propsIcon}>
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={sxPopover}
      >
        {content}
      </Popover>
    </div>
  );
}
