import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface authState {
	page: number;
	rowsPerPage: number;
}

const initialState: authState = {
	page: 0,
	rowsPerPage: 10,
};

const paginationSlice = createSlice({
	name: 'pagination',
	initialState,
	reducers: {
		setPage(state, action) {
			state.page = action.payload;
		},
		setRowsPerPage(state, action) {
			state.rowsPerPage = action.payload;
		},
	},
});

//Actions
export const paginationActions = paginationSlice.actions;

//Selectors
export const selectorSetPage = (state: RootState) => state.pagination.page;
export const selectorSetRowsPerPage = (state: RootState) =>
	state.pagination.rowsPerPage;

//reducer
export default paginationSlice.reducer;
