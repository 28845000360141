import { Airplay } from "@mui/icons-material";
import ApprovalIcon from "@mui/icons-material/Approval";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ContentPasteSearchRoundedIcon from "@mui/icons-material/ContentPasteSearchRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DvrIcon from "@mui/icons-material/Dvr";
import EmailIcon from "@mui/icons-material/Email";
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ParkOutlinedIcon from "@mui/icons-material/ParkOutlined";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import QuizIcon from "@mui/icons-material/Quiz";
import WorkIcon from "@mui/icons-material/Work";
import type { Navigation } from "@toolpad/core";

export const NAVIGATION = [
  {
    kind: "header",
    title: "Dashboard",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE", "U_SALE", "U_SALE_ADMIN"]
  },
  {
    segment: "",
    title: "Home",
    icon: <DashboardIcon fontSize="small" />,
    role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
  },
  {
    segment: "admin/user",
    title: "User",
    icon: <PersonOutlineRoundedIcon fontSize="small" />,
    role: ["U_ADMIN", "U_ADMIN_OF_SALE", "U_SALE", "U_SALE_ADMIN"]
  },
  {
    segment: "admin/company",
    title: "Company",
    icon: <EmojiTransportationRoundedIcon fontSize="small" />,
    role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
  },
  {
    segment: "admin",
    icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
    title: "Jobs",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE"],
    children: [
      {
        title: "Recruit",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "recruit",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Comment Q&A Job",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "comment-questions-and-answers-job",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      }
    ]
  },
  {
    title: "Score diary",
    icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
    segment: "admin/score-diary",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE", "U_SALE", "U_SALE_ADMIN"]
  },
  {
    title: "Logger",
    icon: <DvrIcon fontSize="small" />,
    segment: "admin/logger",
    role: ["U_ADMIN"]
  },
  {
    title: "FAQ",
    icon: <QuizIcon fontSize="small" />,
    segment: "admin/faq",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
  },
  {
    title: "Parse Cv",
    icon: <ParkOutlinedIcon fontSize="small" />,
    segment: "admin/parse-cv",
    role: ["U_ADMIN"]
  },
  {
    kind: "divider",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE", "U_SALE", "U_SALE_ADMIN"]
  },
  {
    kind: "header",
    title: "Management",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE", "U_SALE", "U_SALE_ADMIN"]
  },
  {
    segment: "admin",
    title: "CV Management",
    role: ["U_ADMIN", "U_ADMIN_OF_SALE"],
    icon: <Airplay fontSize="small" />,
    children: [
      {
        title: "Confirm CV",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "confirm-cv",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Upload CV",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "confirm-cv/upload",
        role: ["U_ADMIN"]
      },
      {
        title: "Input CV",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "input-cv",
        role: ["U_ADMIN"]
      },
      {
        title: "Input Employer CV",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "employer-input-cv",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Input Employer File CV",
        icon: <CoPresentIcon fontSize="small" />,
        segment: "employer-input-cv-file",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Cv Applied",
        icon: <ApprovalIcon fontSize="small" />,
        segment: "cv-applied",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Optimize CV",
        icon: <DvrIcon fontSize="small" />,
        segment: "optimize-cv",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      }
    ]
  },
  {
    segment: "admin",
    title: "Blog Management",
    role: ["U_ADMIN"],
    icon: <Airplay fontSize="small" />,
    children: [
      {
        title: "Blog Category",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "blog-category",
        role: ["U_ADMIN"]
      },
      {
        title: "Blog",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "blog",
        role: ["U_ADMIN"]
      }
    ]
  },
  {
    segment: "admin",
    title: "Term Management",
    role: ["U_ADMIN"],
    icon: <Airplay fontSize="small" />,
    children: [
      {
        title: "Skill Term",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "skill-term",
        role: ["U_ADMIN"]
      },
      {
        title: "Education Term",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "education-term",
        role: ["U_ADMIN"]
      },
      {
        title: "Limit Posting & Trading & purchases",
        icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
        segment: "limit-data",
        role: ["U_ADMIN"]
      }
    ]
  },
  {
    segment: "admin",
    title: "Feature Management",
    icon: <Airplay fontSize="small" />,
    role: ["U_ADMIN"],
    children: [
      {
        title: "Transaction",
        icon: <CurrencyExchangeRoundedIcon fontSize="small" />,
        segment: "transaction",
        role: ["U_ADMIN"]
      },
      {
        title: "Package",
        icon: <ConfirmationNumberOutlinedIcon fontSize="small" />,
        segment: "package",
        role: ["U_ADMIN"]
      },
      {
        title: "Release Management",
        icon: <DvrIcon fontSize="small" />,
        segment: "release-management",
        role: ["U_ADMIN"]
      },
      {
        title: "Employer Referer",
        icon: <DvrIcon fontSize="small" />,
        segment: "referer",
        role: ["U_ADMIN", "U_ADMIN_OF_SALE"]
      },
      {
        title: "Email Template",
        icon: <EmailIcon fontSize="small" />,
        segment: "email-template",
        role: ["U_ADMIN"]
      },
      {
        title: "Extend Time Job When Holiday",
        icon: <DvrIcon fontSize="small" />,
        segment: "extend-time-job-holiday"
      },
      {
        title: "Bad Keyword",
        icon: <DvrIcon fontSize="small" />,
        segment: "bad-keyword",
        role: ["U_ADMIN"]
      }
    ]
  }
] as unknown as Navigation & { role?: any };

export const NAVIGATION_INPUT: Navigation = [
  {
    title: "Upload Cv",
    icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
    segment: "input/upload-cv"
  },
  {
    title: "Input Cv",
    icon: <ContentPasteSearchRoundedIcon fontSize="small" />,
    segment: "input/cv"
  }
];

export const NAVIGATION_CONTENT: Navigation = [
  {
    title: "Blog",
    icon: <WorkIcon fontSize="small" />,
    segment: "content/blog"
  },
  {
    title: "Create Blog",
    icon: <LibraryAddIcon fontSize="small" />,
    segment: "content/blog/create"
  },
  {
    title: "Blog Category",
    icon: <WorkIcon fontSize="small" />,
    segment: "content/blog-category"
  }
];
