import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface authState {
	//user
	searchInput: string;
	filterInput: string;
	pathInput: string;
	//paginate
	page: number;
	rowsPerPage: number;
}

const initialState: authState = {
	searchInput: '',
	filterInput: '',
	pathInput: '',
	page: 0,
	rowsPerPage: 10,
};

const homeSlices = createSlice({
	name: 'home',
	initialState,
	reducers: {
		setSearchInput(state, action) {
			state.searchInput = action.payload;
		},

		setFilterInput(state, action) {
			state.filterInput = action.payload;
		},

		setPathInput(state, action) {
			state.pathInput = action.payload;
		},

		setPage(state, action) {
			state.page = action.payload;
		},
		setRowsPerPage(state, action) {
			state.rowsPerPage = action.payload;
		},
	},
});

//Actions
export const homeActions = homeSlices.actions;

//Selectors

//User
export const selectorSetSearchInput = (state: RootState) =>
	state.home.searchInput;
export const selectorSetFilterInput = (state: RootState) =>
	state.home.filterInput;
export const selectorSetPathInput = (state: RootState) => state.home.pathInput;
//paginate
export const selectorSetPage = (state: RootState) => state.home.page;
export const selectorSetRowsPerPage = (state: RootState) =>
	state.home.rowsPerPage;

//reducer
export default homeSlices.reducer;
