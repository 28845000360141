import * as React from "react";

export interface IModalItem {
  value: any;
  isOpen: boolean;
}

export interface IModalContext {
  modals: Record<string, IModalItem>;
  handleOpenModal: (key: string, value: any) => void;
  handleCloseModal: (key: string) => void;
  handleCloseModalCallback: (key: string, value: any) => void;
  getIsOpen: (key: string) => boolean;
}

export const ModalContext = React.createContext<IModalContext>({
  modals: {},
  handleOpenModal: () => {},
  handleCloseModal: () => {},
  getIsOpen: () => false,
  handleCloseModalCallback: () => {}
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modals, setModals] = React.useState<Record<string, IModalItem>>({});

  const handleOpenModal = (key: string, value: any) => {
    setModals((pre) => ({
      ...pre,
      [key]: {
        isOpen: true,
        value
      }
    }));
  };

  const handleCloseModal = (key: string) => {
    setModals((pre) => ({
      ...pre,
      [key]: {
        isOpen: false,
        value: undefined
      }
    }));
  };

  const handleCloseModalCallback = (key: string, data: any) => {
    setModals((pre) => ({
      ...pre,
      [key]: {
        isOpen: false,
        value: data
      }
    }));
  };

  const getIsOpen = (key: string) => {
    return modals[key]?.isOpen || false;
  };

  return (
    <ModalContext.Provider
      value={{
        getIsOpen,
        handleCloseModalCallback,
        handleCloseModal,
        handleOpenModal,
        modals
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = <T,>(key: string) => {
  const context = React.useContext(ModalContext);

  const handleOpenModal = (value: T) => context.handleOpenModal(key, value);
  const handleCloseModal = () => context.handleCloseModal(key);
  const getIsOpen = () => context.getIsOpen(key);
  const handleCloseModalCallback = (data: T) =>
    context.handleCloseModalCallback(key, data);

  return {
    modalValue: context.modals[key]?.value as T,
    handleOpenModal,
    handleCloseModal,
    getIsOpen,
    handleCloseModalCallback
  };
};
